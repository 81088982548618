import components from './map.components.mobile';
import UserLoginMobile from '../pages/user/login/login.mobile';
import UserRegisterMobile from '../pages/user/register/register.mobile';
import UserTransmissionrequestMobile from '../pages/user/transmission-request/transmission-request.mobile';
import FrontendFaqMobile from '../pages/frontend/faq/faq.mobile';
import FrontendEventMobile from '../pages/frontend/event/event.mobile';
import ErrorPageMobile from '../pages/error/error-page.mobile';
import GenericMobile from '../pages/generic/generic.mobile';
import PaymentMethodMobile from '../pages/customer/payment/payment-method.mobile';
import SeminarMobilePage from '../pages/seminar/seminar.mobile';

export const map = {
  components: components,
  pages: {
    user: {
      login: UserLoginMobile,
      register: UserRegisterMobile,
      transmissionrequest: UserTransmissionrequestMobile,
    },
    frontend: {
      faq: FrontendFaqMobile,
      event: FrontendEventMobile,
    },
    customer: {
      'payment.payment-method': PaymentMethodMobile,
    },
    page: {
      generic: GenericMobile,
      seminar: SeminarMobilePage,
      error: ErrorPageMobile,
    },
  },
};
