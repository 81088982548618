import '../../sass/main.mobile.scss';

import Main from './main';
import { map } from '../map/map.mobile';

const __dcl = () => {
  new Main(map, 'mobile');
};

document.addEventListener('DOMContentLoaded', __dcl);
